import './UWU.css';
import { InView } from 'react-intersection-observer';
import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

export default function App() {
    const navigate = useNavigate();

    useEffect(function mount() {
        function onScroll() {
            window.addEventListener('scroll', () => {
                document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight) * 100)
            }, false);
        }

        window.addEventListener("scroll", onScroll);

        return function onMount() {
            window.removeEventListener("scroll", onScroll);
        }
    })

    const navigateLogin = () => {
        navigate('/login');
    };

    return (
        <article className='content-grid'>

            <header className="articleHeader">
                <div>
                    <div className="title">STROMAJER.TOP</div>
                    <div className='btn-placeholder'>
                        <button className='enter-btn' onClick={navigateLogin}>Enter</button>
                    </div>
                    <img
                        className="headerImage"
                        alt='backgroundImg'
                        src={require('./images/naturebg.jpg')}/>
                </div>
            </header>
            
            <InView>
                {({ inView, ref, entry }) => (
                <div className='cardSection'>
                    <div className="sectionColumn cardSectionColumn">
                        <div className="sectionRow" ref={ref}>
                            <img   
                                className={inView ? "cardImg cardImg1" : "cardImg"}
                                alt="Sheoldred, the Apocalypse"
                                src='https://cards.scryfall.io/normal/front/d/6/d67be074-cdd4-41d9-ac89-0a0456c4e4b2.jpg?1674057568'/>
                            <img   
                                className={inView ? "cardImg cardImg2" : "cardImg"}
                                alt="Gishath, Sun's Avatar"
                                src='https://cards.scryfall.io/normal/front/b/c/bc4a65de-23b5-48f0-b8b7-94608eaced3e.jpg?1699044539'/>
                            <img   
                                className={inView ? "cardImg cardImg3" : "cardImg"}
                                alt="Questing Beast"
                                src='https://cards.scryfall.io/normal/front/e/4/e41cf82d-3213-47ce-a015-6e51a8b07e4f.jpg?1572490640'/>
                        </div>
                        <div className="sectionRow cardSectionLink">
                            <a href='https://mtg-collection.stromajer.top/'>MTG Card Collection</a>
                        </div>
                    </div>
                </div>
                )}
            </InView>

            <section className="timerSection">
                <div className="clockSquare">
                    <img   
                            className="clock"
                            alt="clock"
                            src={require('./images/clock.png')}/>
                    <img   
                            className="clockHandMinute"
                            alt="clockHandMinute"
                            src={require('./images/clockHandMinute.png')}/>
                    <img   
                            className="clockHandHour"
                            alt="clockHandHour"
                            src={require('./images/clockHandHour.png')}/>
                    <div className="timerSectionLink">
                        <a href='https://timerbg.stromajer.top/'>Board Game Timer</a>
                    </div>
                </div>
                <div className="timerSectionLinkSmall">
                    <a href='https://timerbg.stromajer.top/'>Board Game Timer</a>
                </div>
            </section>

            <InView>
                {({ inView, ref, entry }) => (
                <div className="footballSection">
                    <div className="sectionColumn footballSectionColumn">
                        <div className="footballSectionRow" ref={ref}>
                            <img
                                className={inView ? "ballAnimation ballImg" : "ballImg"}
                                alt="Ball"
                                src={require('./images/footballBall.png')}/>
                            <img   
                                className={inView ? "footballerAnimation footballerImg" : "footballerImg"}
                                alt="Footballer"
                                src={require('./images/footballer.png')}/>
                        </div>
                        <div className="sectionRow footballSectionLink">
                            <a href='https://ustreli-ga.stromajer.top/'>Ustreli ga</a>
                        </div>
                    </div>
                </div>
                )}
            </InView>
            
        </article>
    );
}