import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import App from './App';
import Login from './Login';
import UWU from './UWU'

const router = createBrowserRouter([
  {
    path: "/",
    //element: <App />,
    element: <UWU />,
  },
  // {
  //   path: "/a",
  //   element: <App />,
  // },
  {
    path: "/login",
    element: <Login />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
