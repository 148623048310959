import './Login.css';

export default function Login() {
    const handleSubmit = (event) => {
        event.preventDefault();
        alert("Your login input was not correct.")
    }

    return (
        <div className='card'>
            <div className='card-content'>
                <div>
                    <div className='title-login'>LOGIN</div>

                    <form method="post" className="login-form" onSubmit={handleSubmit}>
                        <label htmlFor="user-email" className='label'>&nbsp;Email</label>
                        <input id="user-email" className="form-content" type="email" name="email" autoComplete="on" required />
                        
                        <div className="form-border"></div>

                        <label htmlFor="user-password" className='label'>&nbsp;Password</label>
                        <input id="user-password" className="form-content" type="password" name="password" required />

                        <div className="form-border"></div>

                        <input id="submit-btn" type="submit" name="submit" value="LOGIN" />
                    </form>
                </div>
            </div>
            <div className='info-email'>info@stromajer.top</div>
        </div>
    )
}